
.deploy-env-info-container {
    display: flex;
    flex-flow: row;
    flex-grow: 1;
    justify-content: space-between;
    padding: 2px;
    font-size: 14px;
}

.info-list {
    list-style-type: none;
}

.deploy-failed {
    display: flex;
    background-color: indianred;
}

.has-latest {
    background-color: lightgreen;
}

.no-prod {
    background-color: #e6dbb9;
}

.simple-button {
    border: 0;
    outline: 0;
    cursor: pointer;
    color: rgb(60, 66, 87);
    background-color: rgb(255, 255, 255);
    box-shadow: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 8px;
    display: inline-block;
    min-height: 28px;
    transition: background-color .24s,box-shadow .24s;
}

