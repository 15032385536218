@import 'src/variables';

.header-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 2px 0 0 #dadada, 0 2px 0 0 #f5b939;

    .navbar {
        display: flex;
        flex-flow: row;
        width: 100%;
        justify-content: center;
        background: #F2F8FF;
        border-top: 1px solid #DADADA;
        padding: 0;

        ul {
            display: flex;
            flex-flow: row;
            flex: 1 1 100%;
            justify-content: center;
            padding: 0;
            margin: 0;
            transition: var(--transition);

            .nav-bar-menu-item {
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                padding: .75rem 2rem;
                color: #222222;
                transition: var(--transition);
                border: none;
                text-decoration: none;
            }

            .nav-bar-menu-item:focus,
            .nav-bar-menu-item:hover {
                text-decoration: underline;
            }

            .nav-bar-menu-item.active {
                color: $app-blue;
                font-weight: bold;
                border-bottom: 2px solid $app-blue !important;
                margin-bottom: -1px;
            }
        }
    }
}

.header-inner-container {
    display: flex;
    flex-flow: row;
    flex: 1 1 100%;
    width: 100%;
    justify-content: center;
    padding: 2rem 3rem;
    background: #ffffff;
    transition: var(--transition);
}

.header-column {
    display: flex;
    flex-flow: column;

    h1 {
        display: flex;
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        color: #595959;
        margin: 0;
    }

    h1:focus {
        text-decoration: underline;
        outline: none;
    }
}