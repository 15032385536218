.deployment-projects-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 1400px;
    padding: 2rem;
    margin: 0 auto;

    h3 {
        display: flex;
        flex-flow: row;
        width: 100%;
        font-size: 24px;
        font-weight: bold;
        line-height: 28px;
        margin-bottom: 1.5rem;
    }
}