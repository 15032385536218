body {
    display: flex;
    padding: 10px;
    background: lightsteelblue;
    justify-content: center;
}

/*.matches-dev {*/
/*    background-color: lightgreen;*/
/*}*/

.failure {
    color: red;
}

.unknown{
    color: orange;
}

.app-container {
    display: flex;
    flex-flow: column;
    flex: 1 1 100%;
    width: 100%;
    padding: 3rem 2rem;
}

.sub-header {
    font-size: 14pt;
    font-weight: normal ;
    text-align: left;
    border-style: none none solid none;
    padding: .4em 0;
    border-width: thin;
    background-color: #f2f8ff;
    color: #595959;
}