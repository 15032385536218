@import 'src/variables';

html, body {
    height: 100% !important;
    color: #595959 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    padding: 0 !important;
    background-color: #f0f0f0 !important;
    font-family: 'Roboto', BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif  !important;

    @media only screen and (max-width: 867px) {
        overflow: hidden auto;
    }
}

body {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

a {
    color: $app-blue;
    text-decoration: none;
    background-color: transparent;
}

a:hover,
a:focus {
    color: #003674;
    text-decoration: underline;
}