.standard-change-form-outer-container {
    display: flex;
    flex-flow: column;
    gap: 20px
}

.standard-change-form-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 950px;
    padding: 2rem;
    margin: 0 auto;

    h3 {
        display: flex;
        flex-flow: row;
        width: 100%;
        font-size: 24px;
        font-weight: bold;
        line-height: 28px;
        margin-bottom: 1.5rem;
    }

    th, td {
        font-size: 16px;
        padding: 3px;
    }
}