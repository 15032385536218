
table {
    border-collapse: collapse;
    margin: 1em 0;
}



tr {
    background-color: white;
}

tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.034);
}

th {
    min-width: 150px;
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    background-color: white;
}

td {
    padding: 1px 0px;
    font-size: 14px;
    white-space: nowrap;
}