.menu-container {
    display: flex;
    align-items: center;

    .menu {
        width: 200px;
        display: flex;
        flex-direction: column;
        background: #ffffff;
    }
    .menu-item {
        cursor: pointer;
        padding: 5px;
        height: 28px;
        border-bottom: 1px solid rgb(187, 187, 187);
    }
    .menu-item:hover {
        color: #2980b9;
    }

    button.menu-button {
        display: flex;
        padding: 0;
        margin-left: .5rem;

        img {
            width: 16px;
        }
    }

    .dropdown-menu.show {
        padding: .25rem 0 0;
    }

    .dropdown-menu[data-popper-placement="top-end"] {
        transform: translate(8px, -35px) !important;
    }

    .dropdown-menu[data-popper-placement="top-start"]::before,
    .dropdown-menu[data-popper-placement="top-end"]::before {
        position: absolute;
        bottom: -7px;
        right: 9px;
        display: inline-block;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-top: 7px solid #CCC;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
    }

    .dropdown-menu[data-popper-placement="top-start"]::after,
    .dropdown-menu[data-popper-placement="top-end"]::after {
        position: absolute;
        bottom: -6px;
        right: 10px;
        display: inline-block;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        border-top: 6px solid white;
        content: '';
    }

    .dropdown-menu[data-popper-placement="bottom-start"] {
        margin-top: .65rem !important;
        transform: translate(-125px, 25px) !important;
    }

    .dropdown-menu[data-popper-placement="bottom-end"] {
        margin-top: .65rem !important;
        transform: translate(8px, 25px) !important;
    }

    .dropdown-menu[data-popper-placement="bottom-start"]::before,
    .dropdown-menu[data-popper-placement="bottom-end"]::before {
        position: absolute;
        top: -7px;
        right: 9px;
        display: inline-block;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #CCC;
        border-left: 7px solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.2);
        content: '';
    }

    //.dropdown-menu[data-popper-placement="bottom-start"]::after,
    //.dropdown-menu[data-popper-placement="bottom-end"]::after {
    //    position: absolute;
    //    top: -6px;
    //    right: 10px;
    //    display: inline-block;
    //    border-right: 6px solid transparent;
    //    border-bottom: 6px solid white;
    //    border-left: 6px solid transparent;
    //    content: '';
    //}

    .dropdown-item {
        display: flex;
        flex-flow: row;
        width: 100%;
        padding: 0;

        a, button {
            display: flex;
            flex-flow: row;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding: .5rem;
            font-size: 14px;
        }

        a:focus, button:focus,
        a:hover, button:hover {
            box-shadow: none
        }
    }

    .dropdown-item:active {
        background: #e9ecef;
    }

    .dropdown-item:focus,
    .dropdown-item:hover {
        box-shadow: 0 0 0 0.1rem rgba(108, 117, 125, .5);
    }
}